<template>
  <b-card class="">
    <div class="container-loader-membership loader-membership" v-if="!loader_membership">
      <b-spinner class="spinner-membership"></b-spinner>
    </div>
    <div class="w-100" v-else>
      <current-membership class="col-12 mb-2"></current-membership>
      <div class="w-100" >
        <component :is="vue_horizontal" responsive class="horizontal" :displacement="0.3" snap="center" v-if="loader_membership">
          <card-membership 
            v-for="(item, index) in results"
            :key="item.uuid"
            :plan="item"
            :status_plan="status_plan"
            :number_plan_selected="index"
            @update_status="getStatusPlan"
            class="container-card-membership"
          ></card-membership>
        </component>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BSpinner,
} from 'bootstrap-vue'

import cardMembership from '@/views/pages/proposals/cardMembership.vue'
import service from "@/services/proposals";
import { getUserData } from '@/libs/utils/user';

export default {
  components: {
    BCard,
    BSpinner,
    cardMembership,
    CurrentMembership: () => import('./CurrentMembership.vue'),
  },

  data() {
    return {
      results: [],
      status_plan: {},
      loader_membership: false,
      vue_horizontal: false,
    };
  },
  created() {
    this.getPlansMembers();
    this.getStatusPlan();
  },
  async mounted() {
    this.vue_horizontal = (await import('vue-horizontal')).default
  },
  methods: {
    getPlansMembers() {
      service.getPlan().then((response) => {
        this.results = response.results;        
        this.updatePlan(this.results.filter((i) => i.is_active)[0]);
        setTimeout(() => {
          this.loader_membership = true;
        }, 1000);
      });
    },
    updatePlan(active_plan) {
      const user_data = getUserData();
      if (active_plan.uuid !== user_data.current_plan.uuid) {
        user_data.current_plan = active_plan;
        localStorage.setItem('userData', JSON.stringify(user_data))
      }
    },
    getStatusPlan() {
      service.statusPlan().then((response) => {
        this.status_plan = response;
      });
    },

  },
}
</script>
<style scoped>
.container-card-membership {
  width: 330px !important;
}
.horizontal >>> .v-hl-btn svg {
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 8px !important;
  height: 100% !important;
  box-shadow: none !important;
  background: none !important;
}

.horizontal >>> .v-hl-btn-prev {
  background: linear-gradient(to left, #ffffff00 0, white 66%, white) !important;
  padding-right: 24px !important;
}

.horizontal >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, white 66%, white) !important;
  padding-left: 24px !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-prev {
  background: linear-gradient(to right, #ececec 0, #ffffff00 66%, #ffffff00) !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, #ececec 66%, #ececec) !important;
}
.horizontal >>> .v-hl-btn {
  top: 0 !important;
  bottom: 0 !important;
  transform: translateX(0) !important;
}
.container-loader-membership {
  min-height: 500px;
  width: 100%;
}
.loader-membership {
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-membership {
  height: 6em;
  width: 6em;
  color: #acacac;
}
</style>