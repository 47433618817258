var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tooltip',{attrs:{"target":"email-info"}},[_vm._v(_vm._s(_vm.$t('emailInfo')))]),_c('b-card',{staticClass:"card-container",attrs:{"body-class":"border-card-info","header-class":"border-header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.$t('titleUserEmail'))+" "),_c('b-icon',{attrs:{"icon":"question-circle","id":"email-info"}})],1)]},proxy:true}])},[_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"card-subtitle-preference"},[_vm._v(_vm._s(_vm.$t('currentUserEmail'))+" "),_c('b-badge',{class:_vm.profile.is_verified ? 'verified-email' : 'not-verified-email',attrs:{"pill":""}},[_vm._v(" "+_vm._s(_vm.profile.is_verified ? _vm.$t("creator.verified"): _vm.$t("creator.notVerified")))])],1),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"user-email-preference"},[_vm._v(" "+_vm._s(_vm.profile.email))])])])]),_c('b-tooltip',{attrs:{"target":"password-info"}},[_vm._v(_vm._s(_vm.$t('passwordInfo')))]),_c('b-card',{staticClass:"card-container",attrs:{"body-class":"border-card-info","header-class":"border-header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.$t('titlePassword'))+" "),_c('b-icon',{attrs:{"icon":"question-circle","id":"password-info"}})],1)]},proxy:true}])},[_c('validation-observer',{ref:"updatePassword",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.changePass.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-old-password"}},[_vm._v(_vm._s(_vm.$t('oldPassword')))]),_c('validation-provider',{attrs:{"name":_vm.$t('oldPassword'),"vid":_vm.$t('oldPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{class:{'is-invalid': !!errors.length},attrs:{"id":"account-old-password","name":"old-password","type":_vm.passwordFieldTypeOld,"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('oldPassword')},model:{value:(_vm.objPass.last_pass),callback:function ($$v) {_vm.$set(_vm.objPass, "last_pass", $$v)},expression:"objPass.last_pass"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconOld},on:{"click":_vm.togglePasswordOld}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-new-password"}},[_vm._v(_vm._s(_vm.$t('newPassword')))]),_c('validation-provider',{attrs:{"name":_vm.$t('newPassword'),"vid":_vm.$t('newPassword')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-new-password","type":_vm.passwordFieldTypeNew,"name":"new-password","placeholder":_vm.$t('newPassword')},model:{value:(_vm.objPass.new_pass),callback:function ($$v) {_vm.$set(_vm.objPass, "new_pass", $$v)},expression:"objPass.new_pass"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-retype-new-password"}},[_vm._v(_vm._s(_vm.$t('retypePassword')))]),_c('validation-provider',{attrs:{"name":_vm.$t('retypePassword'),"vid":_vm.$t('retypePassword'),"rules":("confirmed:" + (_vm.$t('newPassword')))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-retype-new-password","type":_vm.passwordFieldTypeRetype,"name":"retype-password","placeholder":_vm.$t('retypePassword')},model:{value:(_vm.objPass.new_pass_check),callback:function ($$v) {_vm.$set(_vm.objPass, "new_pass_check", $$v)},expression:"objPass.new_pass_check"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12 justify-content-end d-flex"}},[_c('b-button',{staticClass:"mt-2 button-save mr-1",attrs:{"variant":"button-save","type":"reset"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(" "+_vm._s(_vm.$t('reset'))+" ")]),_c('b-button',{staticClass:"mt-2 button-save",attrs:{"variant":"button-save","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)]}}])})],1),_c('b-tooltip',{attrs:{"target":"payout-info"}},[_vm._v(_vm._s(_vm.$t('payoutInfo')))]),_c('b-tooltip',{attrs:{"target":"vanity-info"}},[_vm._v(_vm._s(_vm.$t('vanityInfo')))]),(_vm.profile.main_group.name === 'creator')?_c('b-card',{staticClass:"card-container",attrs:{"body-class":"border-card-info","header-class":"border-header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"title-text"},[_vm._v("Vanity Url "),_c('b-icon',{attrs:{"icon":"question-circle","id":"vanity-info"}})],1)]},proxy:true}],null,false,3522716994)},[_c('div',{staticClass:"mt-2"},[_c('validation-observer',{ref:"updateVanityName",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form-group',{attrs:{"label":(_vm.domain + "/@" + _vm.vanity_name)}},[_c('validation-provider',{attrs:{"rules":"required"}},[_c('b-input-group',{class:_vm.state_vanity === null ? '' : _vm.state_vanity ? 'is-valid' : 'is-invalid',attrs:{"prepend":"@"}},[_c('b-form-input',{attrs:{"placeholder":"Vanity name","size":"lg","state":_vm.state_vanity},model:{value:(_vm.vanity_name),callback:function ($$v) {_vm.vanity_name=$$v},expression:"vanity_name"}})],1),(_vm.is_vanity_in_use && (_vm.profile.meta_info && _vm.profile.meta_info.vanity_name) && _vm.profile.meta_info.vanity_name !== _vm.vanity_name)?_c('small',{staticClass:"mt-1 d-block text-danger"},[_vm._v(_vm._s(_vm.$t('vanity_in_use')))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12 justify-content-end d-flex p-0"}},[_c('b-button',{staticClass:"mt-2 button-save",attrs:{"variant":"button-save","type":"submit","disabled":invalid || !_vm.state_vanity},on:{"click":_vm.updateVanityName}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)]}}],null,false,3630424611)})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }