<template>
  <b-sidebar
    v-model="show"
    @change="closeSider"
    right
    shadow
    backdrop
    header-class="container-title-sider"
    width="400px"
  >
    <template #header>
      <div>
        <span class="title-sider">{{ $t("editNetwork") }}</span>
        <feather-icon
          class="close-sider-network"
          icon="XIcon"
          size="20"
          @click="show = false"
        />
      </div>
    </template>
    <div class="mt-2 px-2">
      <validation-observer #default="{ invalid }">
        <b-form class="col-12" @submit.prevent="updateNetwork">
          <b-form-group>
            <validation-provider>
              <div class="d-flex justify-content-center">
                <b-avatar
                  @click="showInputFile"
                  :src="avatar"
                  class="edit-avatar"
                  variant="ligth"
                  href="#"
                />
              </div>
              <b-form-file
                ref="input-file"
                v-model="changeAvatar"
                @change="hasChangeFile"
                class="mt-1"
                id="file-large"
                size="lg"
                :placeholder="$t('dashboard.chooseFile')"
                drop-placeholder="$t('dashboard.dropFile')"
                accept=".jpg, .png"
                :browse-text="$t('uploadFile')"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group :label="$t('manually')">
            <validation-provider rules="required">
              <b-input-group prepend="@">
                <b-form-input
                  disabled
                  :placeholder="$t('nameUser')"
                  size="lg"
                  v-model="networkUpdate.username"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
          <b-form-group v-if="netWithoutFollow()">
            <validation-provider rules="required">
              <cleave
                class="form-control"
                :options="options_cleave.number"
                :placeholder="$t('campaigns.followers')"
                v-model="networkUpdate.followers"
              />
            </validation-provider>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button
              type="submit"
              :disabled="invalid || networkUpdate.followers.length > 10"
              class="blue-button btn-position"
              variant="blue-button"
            >
              {{ $t("update") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-sidebar>
</template>
  
<script>
import {
  BAvatar,
  BSidebar,
  BButton,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import utils from "@/libs/utils";
import service from "@/services/others";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import cleave from 'vue-cleave-component';

export default {
  name: "SiderUpdateNetwork",
  components: {
    cleave,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormInput,
    BForm,
    BFormFile,
    BFormGroup,
    BSidebar,
    BButton,
  },
  props: {
    show_sider_update: {
      type: Boolean,
    },
    networkUpdate: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      avatar: "",
      changeAvatar: null,
      utils,
      required,
      show: this.show_sider_update,
      options_cleave: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      },
    };
  },
  created() {
    this.getNetworks();
    if (this.networkUpdate.avatar) {
      this.avatar = utils.getAssetUrl(this.networkUpdate.avatar);
    }
  },
  methods: {
    getNetworks() {
      service
        .getSocialNetworks()
        .then((response) => {
          this.newNetwork = response.results;
        })
        .catch(() => {});
    },
    resetForm() {
      this.newNetwork = {
        network: "",
        username: "",
        is_channel: false,
        followers: 0,
        avatar: ""
      };
    },
    netWithoutFollow() {
      if (this.networkUpdate.network !== 'blog' && this.networkUpdate.network !== 'spotify' && this.networkUpdate.network !== 'linkedin') return true
      else return false
    },
    closeSider() {
      this.$emit("close_siderbar_update");
    },
    showInputFile() {
      this.$refs["input-file"].$refs["input"].click();
    },

    updateNetwork() {
      const formData = new FormData();
      formData.append("username", this.networkUpdate.username);
      formData.append("followers", this.networkUpdate.followers);
      formData.append("network", this.networkUpdate.network);
      formData.append("is_channel", this.networkUpdate.is_channel);
      if (this.changeAvatar !== null) formData.append("avatar", this.changeAvatar);
      service.addSocialNetwork(formData).then((response) => {
        this.$emit("updated_network");
        this.show = false
        if (response)
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t("socialUpdated"),
              icon: "ShuffleIcon",
              variant: "success",
              text: this.$t("updateNetwork"),
            },
          });
      });
      this.resetForm();
    },
    showToast(
      title,
      text,
      variant,
      icon = "AlertIcon",
      position = "top-right"
    ) {
      this.$toast({
        component: ToastificationContent,
        position,
        props: {
          title,
          icon,
          variant,
          text,
        },
      });
    },
    hasChangeFile() {
      setTimeout(() => {
        this.avatar = URL.createObjectURL(this.changeAvatar);
      }, 200);
    },
  },
};
</script>
<style scoped>
.edit-avatar {
  width: 8rem;
  height: 8rem;
  padding: 3px;
}
</style>