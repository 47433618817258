<template>
  <section>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
      class="pt-1"
      v-model="active_tab"
    >

      <!-- general tab -->
      <b-tab :active="$route.params.section === 'general'"
        @click="$router.push({ name: 'pages-account-setting', params: { section: 'general' } }).catch(() => {})">

        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('dashboard.profile')}}</span>
        </template>

        <account-setting-general
        v-if="end_profile && active_tab === 0"
        :optionsLocal="profile"
        />
      </b-tab>
      <!--/ general tab -->

      <b-tab  :active="$route.params.section === 'preference'" 
        @click="$router.push({ name: 'pages-account-setting', params: { section: 'preferences' } }).catch(() => {})">

        <!-- title -->
        <template #title>
          <b-icon
            icon="gear"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('preference')}}</span>
        </template>

        <account-setting-password/>

      </b-tab>

      <!--/ change password tab -->
      <!-- social links -->
      <b-tab v-if="rol === 'creator'" :active="$route.params.section === 'social'"
        @click="$router.push({ name: 'pages-account-setting', params: { section: 'social' } }).catch(() => {})">

        <!-- title -->
        <template #title>
          <feather-icon
            icon="LinkIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('social')}}</span>
        </template>

        <account-setting-social v-if="active_tab"/>
      </b-tab>

       <!-- data payment -->
      <b-tab v-if="rol === 'creator'" :active="$route.params.section === 'payment-data'"
        @click="$router.push({ name: 'pages-account-setting', params: { section: 'payment-data' } }).catch(() => {})">
        <template #title>
          <feather-icon
            icon="DollarSignIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('campaigns.has_payment_data')}}</span>
        </template>
        <account-setting-payment-data :profile="profile" @update_emailPaypalSetting="updateEmailProfile"/>
      </b-tab>


      <b-tab :active="$route.params.section === 'notification'"
        @click="$router.push({ name: 'pages-account-setting', params: { section: 'notification' } }).catch(() => {})">

        <!-- title -->
        <template #title>
          <b-icon
            icon="bell"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('notification')}}</span>
        </template>
        <account-setting-notification-two/>

      </b-tab>

      <b-tab v-if="rol === 'creator'" :active="$route.params.section === 'activity'"
        @click="$router.push({ name: 'pages-account-setting', params: { section: 'activity' } }).catch(() => {})">

        <!-- title -->
        <template #title>
          <b-icon
            icon="calendar2-check"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('activity')}}</span>
        </template>
        <account-setting-activity/>

      </b-tab>

      <b-tab v-if="rol !== 'realty'" :active="$route.params.section === 'membership'"
        @click="$router.push({ name: 'pages-account-setting', params: { section: 'membership' } }).catch(() => {})">
        <template #title>
          <b-icon
            icon="card-checklist"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('membership')}}</span>
        </template>
        <account-setting-membership v-if="$route.params.section === 'membership'"/>
      </b-tab>

      <b-tab 
        v-if="rol !== 'realty'" 
        :active="$route.params.section === 'billing'"
        @click="$router.push({ name: 'pages-account-setting', params: { section: 'billing' } }).catch(() => {})">
        <template #title>
          <b-img 
            :src="require('@/assets/images/svg/billing.svg')" 
            class="img-18 mr-50"
            :class="{'img-18-white': $route.params.section === 'billing'}"
          ></b-img>
          <span class="font-weight-bold">{{$t('memberships.billing')}}</span>
        </template>
        <account-setting-billing/>
      </b-tab>


      <!-- Visited profiles -->
      <b-tab 
        v-if="showProfiles()" 
        :active="$route.params.section === 'visitedProfiles'"
        @click="$router.push({ name: 'pages-account-setting', params: { section: 'visitedProfiles' } }).catch(() => {})"
      >
       <template #title>
         <feather-icon
           icon="EyeIcon"
           size="18"
           class="mr-50"
         />
         <span class="font-weight-bold">{{$t('profileVisited')}}</span>
       </template>

       <account-setting-profiles-visited  />


     </b-tab>
      <!-- Brands -->
      <b-tab v-if="rol === 'brand'" :active="$route.params.section === 'brand'">
        <!-- title -->
        <template #title>
          <feather-icon
            icon="BriefcaseIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">{{$t('brand')}}</span>
        </template>

        <account-setting-brands/>
      </b-tab>
      <!--/ social links -->
    </b-tabs>
  </section>
</template>

<script>
import { BTabs, BTab, BIcon, BImg } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingBrands from './AccountSettingBrands.vue'
import AccountSettingPaymentData from './AccountSettingPaymentData.vue'
import AccountSettingActivity from './AccountSettingActivity.vue'
import AccountSettingNotificationTwo from './AccountSettingNotificationTwo.vue'
import service from '@/services/others'
import utils from '@/libs/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AccountSettingProfilesVisited from './AccountSettingProfilesVisited.vue';
import AccountSettingMembership from '@/views/pages/account-setting/AccountSettingMembership.vue';

export default {
  components: {
    BIcon,
    BTabs,
    BTab,
    BImg,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingSocial,
    AccountSettingBrands,
    AccountSettingProfilesVisited,
    AccountSettingPaymentData,
    AccountSettingMembership,
    AccountSettingActivity,
    AccountSettingNotificationTwo,
    AccountSettingBilling: () => import('./AccountSettingBilling.vue')
  },
  data() {
    return {
      active_tab: 0,
      rol: '',
      end_profile: false,
      utils,
      profile: {
        main_group: {
          name: ''
        }
      },
      main_brand: {},
      email_paypal: '',
      viewEditBrand: false,
    }
  },
  beforeCreate () {
    service.getProfile()
      .then(response => {
        this.profile = response.user
        if (this.rol !== 'creator' && this.rol !== 'realty') this.main_brand = (response.account_company && response.account_company.main_brand) ? response.account_company.main_brand : {}
        this.viewEditBrand = true
        this.end_profile = true
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('messageAccountSettingError'),
            icon: 'CoffeeIcon',
            variant: 'danger',
            text:this.$t('messageAccountSettingFailed')
          }
        })
        utils.removeLocal()
      })
    this.$store.commit('appConfig/UPDATE_IS_VERIFIED', utils.checkVerified());
  },
  destroyed() {
    const body = document.querySelector('div.app-content.content');
    body.removeAttribute('style');
  },
  created () {
    this.rol = utils.getUserData().groups[0].name;
  },
  methods: {
    updateEmailProfile(email) {
      if (this.profile.paypal_info) {
        this.profile.paypal_info.email_paypal = email;
      } else {
        this.profile.paypal_info = { email_paypal: email };
      }
    },
    showProfiles() {
      if (this.rol === 'creator') return false
      if (this.rol === 'realty') return false
      return true
    },
    isSuperUser() {
      return utils.getUserData().is_superuser
    }
  }
}
</script>
<style lang="scss" scoped>
.img-18 {
  width: 18px;
  height: 18px;
}
.img-18-white {
  filter: invert(1) grayscale(1) brightness(100);
}
</style>